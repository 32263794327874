//= config.js
//= utils/index.js
//= auth.js
//= forgotPassword.js
//= register.js
//= slider.js
//= activateVip.js
//= activateVipSlider.js
//= profileInTopSlider.js
//= giftsSlider.js
//= find.js
//= modalHash.js
//= passwordNew.js
//= help.js

$(window).resize(function() {

    const isOpenModalScreen2 = $("#activateVipScreen2-modal").hasClass("show");
    const isOpenModalScreenInboxMessages = $("#inboxMessages-modal").hasClass("show");
    const isOpenModalScreenAboutMe = $("#aboutMe-modal").hasClass("show");

    if (window.innerWidth > tabletMedia) {
        if (isOpenModalScreenInboxMessages) {
            $("#inboxMessages-modal").modal("hide");
        }
        if (isOpenModalScreen2) {
            $("#activateVipScreen2-modal").modal("hide");
        }
        if (isOpenModalScreenAboutMe) {
            $("#aboutMe-modal").modal("hide");
        }
    }

});

function closeModal(idModal) {

    $(idModal).modal("hide");

}

function closeDropdown(idDropdown) {

    $(idDropdown).removeClass('show');

}

function closeVideoModal(idModal) {
    const $iframes = $(idModal+'.modal-video iframe');
    $iframes.each(function(){
        var src = $(this).attr('src');      
        $(this).attr('src', '').attr('src', src);
    });
    closeModal(idModal);
}

$('.modal.lazy-iframe').on("show.bs.modal", function () {
    // "ленивая" загрузка iframe, используется для встроенного видео
    console.log(".modal.lazy-iframe this=", this)
    $(this).find("iframe").each(function(){
        var iframe = $(this);
        iframe.attr('src', iframe.data('src'));
    });
});
